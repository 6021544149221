.completePage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > div {
        font-size: 20px;
        margin: 56px 0 36px;
    }
}
