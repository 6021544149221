* {
  margin: 0;
  box-sizing: border-box;
}

.ol,
.ul {
  list-style: none;
}

.underline {
  text-decoration: underline;
}
.whitespace-break-spaces {
  white-space: break-spaces;
}
sub {
  vertical-align: baseline;
}
