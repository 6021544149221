.App {
  .content {
    max-width: 980px;
    padding: 0 8px;
    margin: 0 auto;


    h2 {
      margin-top: 23px;
      font-size: 24px;
      font-weight: 400;
      color: #333333;
    }

    h3 {
      margin-top: 23px;
      font-size: 20px;
      font-weight: 600;
      color: #000000;
    }

    .pabbit-lite {
      margin-top: 23px;
      font-size: 24px;
      font-weight: 400;
      color: #333333;
    }
  }
}
