@counter-style bracket-decimal {
    system: fixed;
    /* list item will use list symbol with this order */
    symbols: '(1)' '(2)' '(3)' '(4)' '(5)' '(6)' ⑦ ⑧ ⑨;
    suffix: ' ';
}
@counter-style circle-decimal {
    system: fixed;
    /* list item will use list symbol with this order */
    symbols: ① ② ③ ④ ⑤ ⑥ ⑦ ⑧ ⑨;
    suffix: ' ';
}
.container {
    display: flex;
    height: calc(100vh - 86px);
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .scrollable {
        height: 300px;
        flex: 1 1 auto;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content {
        background-color: #fafafa;
        flex: 1;
        overflow: auto;
        font-family: sans-serif;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 48px;
        zoom: 0.8;
        width: 100%;
        word-break: break-word;
        a {
            word-break: break-all;
        }

        p {
            margin-top: 16px;
        }
        h2 {
            text-align: center;
            font-weight: bold;
        }
        ol, ul {
            margin-top: 4px;
            list-style: none;
        }
        ul {
            margin-left: 25px;
        }
        ol.bracketDecimal {
            // list-style: bracket-decimal;
            > li {
                position: relative;
                &::before {
                    display: inline-block;
                    position: absolute;
                    left: -22px;
                    top: 1px;
                }
            }
            > li:nth-child(1)::before {
                content: '(1)';
            }
            > li:nth-child(2)::before {
                content: '(2)';
            }
            > li:nth-child(3)::before {
                content: '(3)';
            }
            > li:nth-child(4)::before {
                content: '(4)';
            }
            > li:nth-child(5)::before {
                content: '(5)';
            }
            > li:nth-child(6)::before {
                content: '(6)';
            }
            > li:nth-child(7)::before {
                content: '(7)';
            }
            > li:nth-child(8)::before {
                content: '(8)';
            }
            > li:nth-child(9)::before {
                content: '(9)';
            }
        }
        ol.circleDecimal {
            > li {
                position: relative;
                &::before {
                    display: inline-block;
                    position: absolute;
                    left: -22px;
                    top: 1px;
                }
            }

            > li:nth-child(1)::before {
                content: '①';
            }
            > li:nth-child(2)::before {
                content: '②';
            }
            > li:nth-child(3)::before {
                content: '③';
            }
            > li:nth-child(4)::before {
                content: '④';
            }
            > li:nth-child(5)::before {
                content: '⑤';
            }
            > li:nth-child(6)::before {
                content: '⑥';
            }
            > li:nth-child(7)::before {
                content: '⑦';
            }
            > li:nth-child(8)::before {
                content: '⑧';
            }
            > li:nth-child(9)::before {
                content: '⑨';
            }
        }
    }

    .toolbar {
        margin-top: 24px;
        margin-bottom: 46px;
    }
}
@media (min-width: 640px) {
    .container .content {
        zoom: 1;
        padding-left: 48px;
        padding-right: 48px;
    }
}