.button {
    border-radius: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 19px;
    line-height: 22px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    user-select: none;
    transition: all ease 0.5s;
    &[aria-disabled="true"] {
        cursor: not-allowed;
        &.light, &.dark{
            filter: brightness(0.85);
        }
    }

    &:hover {
        filter: brightness(0.95);
    }

    &.light {
        background-color: #4EACC8;
    }

    &.dark {
        background-color: #6C819E;
    }

    &.danger {
        background-color: #fff;
        color: #C00B0B;
    }

    .loadingIcon {
        animation: rotation 1s infinite ease-in-out;
        margin-right: 16px;
    }
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}