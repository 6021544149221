.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .content {
        width: 100%;
        max-width: 980px;
        margin-top: 23px;

        form {
            width: 100%;
        }

        .pdfContainer {
            width: 100%;
            overflow-y: hidden;
            margin-top: 16px;

            .pdfDocument {
                width: 100%;
            }

            .agreementForm {
                width: 100%;
                display: flex;
                column-gap: 20px;

                label {
                    font-size: 16px;
                }

                input {
                    margin-right: 8px;
                }
            }

            .errorMessage {
                color: #C00B0B;
                font-size: 12px;
                padding-left: 20px;
                margin-top: 4px;
            }
        }

        .lastFormContainer {
            margin-top: 23px;

            .toolbar {
                margin-bottom: 16px;
                font-size: 14px;

                input {
                    margin-right: 8px;
                }

                p {
                    color: #C00B0B;
                    padding-left: 20px;
                    font-size: 12px;
                }
            }

            .buttonsContainer {
                margin-top: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .groupFormItem {
                margin-bottom: 16px;
                position: relative;

                .deleteButton {
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    padding: 9px 39px;
                    font-size: 14px;
                }
            }
        }
    }

    .toolbar {
        margin-top: 23px;
    }

    .pagination {
        color: #cccccc;
        margin-top: 24px;
    }

    .postalCodeSign {
        position: absolute;
        color: #444;
        right: -20px
    }

    .formHeader {
        height: 90px;
    }

    .subtitle {
        font-size: 12px;
    }

    .formItemContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        .errorMessage {
            color: #C00B0B;
            font-size: 12px;
            text-align: center;
        }
    }

    .pdfFormHeader {
        height: 72px;
    }

    .formItem {
        display: flex;
        align-items: center;
        justify-items: start;
        gap: 4px;
        height: 40px;
        margin-bottom: 1px;

        label {
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
            height: 40px;
            background-color: #6C819E;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            width: 190px;
        }

        select {
            flex: 1;
            height: 36px;
            padding: 0 8px;
            font-size: 14px;
            min-width: 0;
        }
    }
}

@media only screen and (max-width: 600px) {
    .container {
        .formItem {
            label {
                width: 140px;
            }
        }
    }
}