.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 86px;
    border-bottom: 1px solid #c0c0c0;

    img {
        height: 80px;
    }
}