.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .content {
        width: 100%;
        max-width: 980px;
        margin-top: 23px;

        form {
            width: 100%;

            label {
                min-width: 240px;
                max-width: 240px;
            }

            input::placeholder {
                color: #ccc;
                font-size: 12px;
                opacity: 0;
            }

            .formItemContainer {
                display: flex;
                flex-direction: column;
                width: 100%;

                .errorMessage {
                    color: #C00B0B;
                    font-size: 12px;
                    text-align: center;
                }
            }

            .formItem {
                display: flex;
                align-items: center;
                justify-items: start;
                gap: 4px;
                height: 40px;
                margin-bottom: 1px;

                label {
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: 500;
                    height: 40px;
                    background-color: #6C819E;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    text-align: right;
                }
        
                select {
                    flex: 1;
                    height: 36px;
                    padding: 0 8px;
                    font-size: 14px;
                    min-width: 0;
                }
            }
        }

        .buttonsContainer {
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .groupFormItem {
            margin-bottom: 16px;
            position: relative;

            .deleteButton {
                position: absolute;
                right: 12px;
                top: 12px;
                padding: 4px 39px;
                font-size: 14px;
            }
        }
    }

    .toolbar {
        margin-top: 23px;
        .button {
            width: 164px;
        }
    }

    .pagination {
        color: #cccccc;
        margin-top: 24px;
    }

    .info {
        color: #999;
        font-size: 12px;
        margin: 24px;

    }
}

@media only screen and (max-width: 600px) {
    .container {
        .content {
            form {
                label {
                    width: 156px;
                    min-width: 156px;
                    padding-left: 0;

                    .subLabel {
                        display: none;
                    }
                }

                input::placeholder {
                    opacity: 1;
                }
            }
        }
    }
}