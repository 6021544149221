.formHeader {
    width: 100%;
    height: 54px;
    background-color: #54657D;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    column-gap: 24px;
    line-height: 16px;
    row-gap: 2px;

    span {
        font-size: 12px;
        line-height: 14px;
    }
}