.formItemContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .formItem {
        display: flex;
        align-items: center;
        justify-items: start;
        gap: 4px;
        height: 40px;
        margin-bottom: 1px;

        label {
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
            height: 40px;
            background-color: #6C819E;
            padding: 0 10px;
            display: flex;
            align-items: center;
            width: 190px;
            justify-content: flex-end;
            text-align: right;
            position: relative;
            white-space: wrap;
        }

        input {
            flex: 1;
            height: 36px;
            padding: 0 16px;
            font-size: 14px;
            min-width: 0;
        }

        .phoneNumberContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            flex-wrap: nowrap;
            flex: 1;

            input {
                &:first-child {
                    flex: 1;
                }

                width: 10px;
                flex: 2;
            }
        }
    }
    .errorMessage {
        color: #C00B0B;
        font-size: 12px;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    .formItemContainer {
        .formItem {
            label {
                width: 140px;
            }
        }
    }
}
